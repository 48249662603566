import {useMediaQuery} from 'react-responsive';
import {MediaType} from '@/types';
import {BREAKPOINTS} from '@/constants';

const useMediaTablet = (type?: MediaType) => {
  const isTablet = useMediaQuery({
    minWidth: BREAKPOINTS.sm,
    maxWidth: BREAKPOINTS.md - 1,
  });
  const isTabletMin = useMediaQuery({
    minWidth: BREAKPOINTS.sm,
  });
  const isTabletMax = useMediaQuery({maxWidth: BREAKPOINTS.md - 1});

  switch (type) {
    case 'less-than':
      return !!isTabletMax;
    case 'greater-than':
      return !!isTabletMin;
    default:
      return !!isTablet;
  }
};

export default useMediaTablet;
