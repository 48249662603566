import {useMediaQuery} from 'react-responsive';
import {BREAKPOINTS} from '@/constants';

const useMediaMobile = () => {
  const isMobile = useMediaQuery({
    maxWidth: BREAKPOINTS.xs - 1,
  });

  return !!isMobile;
};

export default useMediaMobile;
