import {useEffect} from 'react';
import Button from '@/components/Button';

import logoImage from '@/assets/images/logo.svg';
import logoText from '@/assets/images/logo-text.svg';
import googlePlayImage from '@/assets/images/google-play.svg';
import appStoreImage from '@/assets/images/app-store.svg';

import styles from './Home.module.scss';
import {FEATURES} from './Home.constants';
import {FeatureWithId} from './Home.types';
import {APP_STORE_URL, APP_TITLE, GOOGLE_PLAY_URL} from '@/constants';

const Home = () => {
  useEffect(() => {
    document.title = `${APP_TITLE} | Home`;
  }, []);

  const renderFeature = ({id, image, title, description}: FeatureWithId) => {
    return (
      <div key={id} className={styles.feature}>
        <div className={styles.featureImage}>
          <img src={image} alt="" />
        </div>
        <div className={styles.featureInfo}>
          <h4 className={styles.featureTitle}>{title}</h4>
          <p
            className={styles.featureDescription}
            dangerouslySetInnerHTML={{__html: description}}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.logo}>
          <img className={styles.logoImage} src={logoImage} alt="" />
          <img src={logoText} alt="" />
        </div>
        <div className={styles.info}>
          <h1 className={styles.title}>
            Infinite Creator presents Contact Exchange System
          </h1>
          <p className={styles.description}>
            Send your electronic business card to clients and colleagues and
            receive their contact details directly to your smartphone!
          </p>
        </div>
        <div className={styles.features}>{FEATURES.map(renderFeature)}</div>
        <div className={styles.buttons}>
          <Button
            size="lg"
            theme="dark"
            type="button"
            href={APP_STORE_URL}
            className={styles.button}
          >
            <img src={appStoreImage} alt="" />
          </Button>
          <Button
            size="lg"
            theme="dark"
            type="button"
            href={GOOGLE_PLAY_URL}
            className={styles.button}
          >
            <img src={googlePlayImage} alt="" />
          </Button>
        </div>
        <div className={styles.note}>Available only for IC Users</div>
      </div>
    </div>
  );
};

export default Home;
