import {forwardRef, memo} from 'react';

import TextField from '@/components/TextField';
import Button from '@/components/Button';
import Media from '@/components/Media';
import Avatar from '@/components/Avatar';

import logoImage from '@/assets/images/logo.svg';
import phoneIcon from '@/assets/icons/phone.svg';
import emailIcon from '@/assets/icons/email.svg';

import {ProfileCardProps} from './types';

import styles from './ProfileCard.module.scss';
import {DOWNLOAD_APP_URL} from '@/constants';

const ProfileCard = forwardRef<HTMLDivElement, ProfileCardProps>(
  ({userData}, ref) => {
    const logoBlock = (
      <div className={styles.logoWrapper}>
        <img src={logoImage} alt="" />
      </div>
    );

    return (
      <div ref={ref} className={styles.wrapper}>
        <div className={styles.preview}>
          <Avatar
            src={userData?.avatar}
            username={userData?.full_name || 'User'}
          />
          <Media.Phablet type="greater-than">{logoBlock}</Media.Phablet>
        </div>
        <div className={styles.content}>
          <div className={styles.contentTop}>
            <Media.Mobile>{logoBlock}</Media.Mobile>
            <h3 className={styles.title}>{userData?.full_name || 'User'}</h3>
            <div className={styles.mainFields}>
              <TextField
                label="Name of the company"
                value={userData?.company_name}
              />
              <TextField
                className={styles.position}
                label="Position"
                value={userData?.role}
              />
              <TextField label="Address" value={userData?.company_address} />
            </div>
            <div className={styles.otherFields}>
              <a href={`tel:${userData?.phone_number}`}>
                <TextField icon={phoneIcon} value={userData?.phone_number} />
              </a>
              <a href={`mailto:${userData?.work_email}`}>
                <TextField icon={emailIcon} value={userData?.work_email} />
              </a>
            </div>
          </div>
          <Media.Mobile>
            <Button
              type="button"
              href={DOWNLOAD_APP_URL}
              className={styles.button}
            >
              Download app
            </Button>
          </Media.Mobile>
        </div>
      </div>
    );
  }
);

ProfileCard.displayName = 'ProfileCard';

export default memo(ProfileCard);
