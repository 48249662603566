import {
  FC,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import cn from 'classnames';

import {useScrollable} from '@/hooks';

import {KEYBOARD_KEYS} from '@/constants';

import closeIcon from '@/assets/icons/close.svg';

import styles from './Popup.module.scss';

export interface PopupProps {
  isOpen: boolean;
  children?: ReactNode;
  popupClassName?: string;
  onClose?: () => void;
}

const Popup: FC<PopupProps> = ({isOpen, children, onClose, popupClassName}) => {
  const {isScrollable, ref} = useScrollable<HTMLDivElement>();
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onClose) return;
    isOpen && popupRef.current?.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleMouseClose = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (!ref.current) return;
      if (!ref.current.contains(e.target as Node)) {
        onClose?.();
      }
    },
    [onClose, ref]
  );

  const handleKeyboardClose = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (!ref.current) return;
      if (!ref.current.contains(e.target as Node)) {
        switch (e.key) {
          case KEYBOARD_KEYS.escape:
            onClose?.();
            break;
        }
      }
    },
    [onClose, ref]
  );

  if (!isOpen) return null;

  return (
    <div
      tabIndex={0}
      {...(!!onClose && {
        ref: popupRef,
        onClick: handleMouseClose,
        onKeyDown: handleKeyboardClose,
      })}
      className={cn(styles.popup, {
        [styles.popupScrollable]: isScrollable,
      })}
    >
      <div ref={ref} className={cn(styles.wrapper, popupClassName)}>
        {!!onClose && (
          <button className={styles.close} type="button" onClick={onClose}>
            <img src={closeIcon} alt="" />
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

Popup.defaultProps = {
  isOpen: false,
};

export default Popup;
