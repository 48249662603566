import {FC} from 'react';
import NotFound from '@/containers/NotFound';

interface WithErrorProps {
  error: Error | unknown;
}

const WithError: FC<WithErrorProps> = ({error, children}) => {
  if (error) return <NotFound />;
  return <>{children}</>;
};

export default WithError;
