import {useEffect, useRef, useState} from 'react';
import {getAbsoluteHeight} from '@/helpers';

const useScrollable = <T extends HTMLElement>() => {
  const [isScrollable, setIsScrollable] = useState(false);
  const ref = useRef<T>(null);

  useEffect(() => {
    checkScrollable();
    window.addEventListener('resize', checkScrollable);
    return () => {
      window.removeEventListener('resize', checkScrollable);
    };
  }, []);

  const checkScrollable = () => {
    if (!ref?.current) return;
    const height = getAbsoluteHeight(ref.current);
    setIsScrollable(height >= window.innerHeight);
  };

  return {isScrollable, ref};
};

export default useScrollable;
