import {FC, memo} from 'react';
import cn from 'classnames';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  className?: string;
}

const Spinner: FC<SpinnerProps> = ({className}) => {
  return <div className={cn(styles.loader, className)} />;
};

export default memo(Spinner);
