import {FC} from 'react';

import Spinner from '@/components/Spinner';

import styles from './Loader.module.scss';

interface LoaderProps {
  loading: boolean;
}

const Loader: FC<LoaderProps> = ({loading, children}) => {
  return (
    <>
      {loading && (
        <div className={styles.wrapper}>
          <Spinner className={styles.spinner} />
        </div>
      )}
      {children}
    </>
  );
};

export default Loader;
