import {FC, memo} from 'react';

import Popup, {PopupProps} from '@/components/Popup';
import Button from '@/components/Button';

import googlePlayImage from '@/assets/images/google-play.svg';
import appStoreImage from '@/assets/images/app-store.svg';

import {UserEntity} from '@/types';

import styles from './PopupDownload.module.scss';
import {APP_STORE_URL, GOOGLE_PLAY_URL} from '@/constants';

interface PopupDownloadProps extends PopupProps {
  userData: UserEntity | null;
}

const PopupDownload: FC<PopupDownloadProps> = ({userData, ...props}) => {
  return (
    <Popup {...props}>
      <h3 className={styles.title}>
        We have just texted you {userData?.full_name || 'User'}’s Contact card
      </h3>
      <p className={styles.description}>
        Want to have the same digital business card? Sign up now to increase
        your effieciency.
      </p>
      <div className={styles.buttons}>
        <Button
          type="button"
          theme="dark"
          href={GOOGLE_PLAY_URL}
          className={styles.button}
        >
          <img src={googlePlayImage} alt="" />
        </Button>
        <Button
          type="button"
          theme="dark"
          href={APP_STORE_URL}
          className={styles.button}
        >
          <img src={appStoreImage} alt="" />
        </Button>
      </div>
    </Popup>
  );
};

export default memo(PopupDownload);
