import {
  forwardRef,
  useEffect,
  useRef,
  useState,
  memo,
  InputHTMLAttributes,
} from "react";
import intlTelInput from "intl-tel-input";
import { DeepMap, FieldError, FieldErrors } from "react-hook-form";
import Inputmask from "inputmask";
import { ErrorMessage } from "@hookform/error-message";

import { useMergedRef } from "@/hooks";

import { DEFAULT_PHONE_INPUT_PLACEHOLDER } from "@/constants";
import { PREFERRED_COUNTRIES, UTILS_PATH } from "./constants";

import styles from "./Input.module.scss";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  inputPlaceholder?: string;
  errors?: FieldErrors;
  onDialCodeChange?: (dialCode: string) => void;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    { label, errors, name, inputPlaceholder, onDialCodeChange, ...props },
    ref
  ) => {
    const [selectedCode, setSelectedCode] = useState("1");
    const inputRef = useRef<HTMLInputElement>(null);
    const mergedRef = useMergedRef(ref, inputRef);

    useEffect(() => {
      if (!inputRef.current) return;
      if (inputRef.current.type !== "tel") return;

      const initializeIntlTelInput = () => {
        intlTelInput(inputRef.current!, {
          autoPlaceholder: "aggressive",
          utilsScript: UTILS_PATH,
          separateDialCode: true,
          preferredCountries: PREFERRED_COUNTRIES,
          customPlaceholder: (
            selectedCountryPlaceholder,
            selectedCountryData
          ) => {
            if (!inputRef.current) return "";
            if (
              inputRef.current.getAttribute("placeholder") ===
              selectedCountryPlaceholder
            ) {
              return "";
            }

            setSelectedCode(selectedCountryData.dialCode);
            inputRef.current?.setAttribute(
              "placeholder",
              selectedCountryPlaceholder
            );

            const updatedCountryPlaceholder =
              selectedCountryPlaceholder.replace(/[0-9]/g, "9");

            Inputmask({
              mask: updatedCountryPlaceholder,
              placeholder: inputPlaceholder,
            }).mask(inputRef.current);

            return selectedCountryPlaceholder;
          },
        });
      };

      window.addEventListener("load", initializeIntlTelInput);

      return () => {
        window.removeEventListener("load", initializeIntlTelInput);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      onDialCodeChange?.(selectedCode);
    }, [selectedCode, onDialCodeChange]);

    const input = (
      <input ref={mergedRef} className={styles.input} name={name} {...props} />
    );

    return (
      <div className={styles.wrapper}>
        {label ? (
          <label className={styles.label}>
            <span>{label}</span>
            {input}
            <ErrorMessage
              name={name!}
              errors={errors}
              render={({ message }) => (
                <p className={styles.error}>{message}</p>
              )}
            />
          </label>
        ) : (
          input
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

Input.defaultProps = {
  inputPlaceholder: DEFAULT_PHONE_INPUT_PLACEHOLDER,
};

export default memo(Input);
