import {FC, ImgHTMLAttributes, memo} from 'react';
import cn from 'classnames';

import facingCover from '@/assets/icons/user.svg';

import styles from './Avatar.module.scss';

interface AvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  username?: string;
}

const Avatar: FC<AvatarProps> = ({src, username}) => {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapperCover]: !src,
      })}
    >
      <img src={src || facingCover} alt={`${username}'s Avatar`} />
    </div>
  );
};

Avatar.defaultProps = {
  username: 'User',
};

export default memo(Avatar);
