import {FC, ReactElement} from 'react';
import {isLocalHost} from './helpers';

interface HttpsRedirectProps {
  disabled?: boolean;
  children: ReactElement;
}

const HttpsRedirect: FC<HttpsRedirectProps> = ({disabled, children}) => {
  if (
    !disabled &&
    typeof window !== 'undefined' &&
    window.location &&
    window.location.protocol === 'http:' &&
    !isLocalHost(window.location.hostname)
  ) {
    window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
    return null;
  }

  return children;
};

export default HttpsRedirect;
