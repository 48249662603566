import {ReactText, FC, memo} from 'react';
import cn from 'classnames';

import styles from './TextField.module.scss';

interface TextFieldProps {
  label?: string;
  value?: ReactText;
  icon?: any;
  className?: string;
}

const TextField: FC<TextFieldProps> = ({label, value, icon, className}) => {
  return (
    <div className={className}>
      {label && <div className={styles.label}>{label}</div>}
      <div
        className={cn(styles.valueWrapper, {
          [styles.valueWrapperWithIcon]: !!icon,
        })}
      >
        {icon && (
          <div className={styles.icon}>
            <img src={icon} alt="" />
          </div>
        )}
        <div className={styles.value}>{value || '—'}</div>
      </div>
    </div>
  );
};

export default memo(TextField);
