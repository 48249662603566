import {useMediaQuery} from 'react-responsive';
import {MediaType} from '@/types';
import {BREAKPOINTS} from '@/constants';

const useMediaPhablet = (type?: MediaType) => {
  const isPhablet = useMediaQuery({
    minWidth: BREAKPOINTS.xs,
    maxWidth: BREAKPOINTS.sm - 1,
  });
  const isPhabletMin = useMediaQuery({minWidth: BREAKPOINTS.xs});
  const isPhabletMax = useMediaQuery({maxWidth: BREAKPOINTS.sm - 1});

  switch (type) {
    case 'less-than':
      return !!isPhabletMax;
    case 'greater-than':
      return !!isPhabletMin;
    default:
      return !!isPhablet;
  }
};

export default useMediaPhablet;
