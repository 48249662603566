import {Ref, RefCallback, MutableRefObject} from 'react';

const useMergedRef = <T extends HTMLElement>(
  ...refs: Ref<T>[]
): RefCallback<T> => {
  return (element: T) => {
    return refs.forEach((ref) => {
      if (typeof ref === 'function') ref(element);
      else if (ref && typeof ref === 'object')
        (ref as MutableRefObject<T>).current = element;
    });
  };
};

export default useMergedRef;
