import cn from "classnames";
import { InputHTMLAttributes, forwardRef, useEffect, useState } from "react";
import styles from "./Checkbox.module.scss";

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ children, ...props }, forwardedRef) => {
    return (
      <label className={styles.wrapper}>
        <input ref={forwardedRef} type="checkbox" {...props} />
        <div
          aria-hidden="true"
          className={cn(styles.checkbox, {
            [styles["checkbox--active"]]: props.checked,
          })}
        />
        <div>{children}</div>
      </label>
    );
  }
);

export default Checkbox;
