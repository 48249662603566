import {FC, ReactNode} from 'react';

import {MediaType} from '@/types';

import {
  useMediaMobile,
  useMediaPhablet,
  useMediaTablet,
  useMediaLaptop,
  useMediaDesktop,
} from '@/hooks';

interface MediaProps {
  children: ReactNode;
  type?: MediaType;
}

interface MediaComponent {
  Mobile: FC<MediaProps>;
  Phablet: FC<MediaProps>;
  Tablet: FC<MediaProps>;
  Laptop: FC<MediaProps>;
  Desktop: FC<MediaProps>;
}

// @ts-ignore
const Media: MediaComponent = {};

const Mobile: FC<MediaProps> = ({children}) => {
  const isMobile = useMediaMobile();
  return isMobile ? <>{children}</> : null;
};

const Phablet: FC<MediaProps> = ({children, type}) => {
  const isPhablet = useMediaPhablet();
  const isPhabletMin = useMediaPhablet('greater-than');
  const isPhabletMax = useMediaPhablet('less-than');

  switch (type) {
    case 'less-than':
      return isPhabletMax ? <>{children}</> : null;
    case 'greater-than':
      return isPhabletMin ? <>{children}</> : null;
    default:
      return isPhablet ? <>{children}</> : null;
  }
};

const Tablet: FC<MediaProps> = ({children, type}) => {
  const isTablet = useMediaTablet();
  const isTabletMin = useMediaTablet('greater-than');
  const isTabletMax = useMediaTablet('less-than');

  switch (type) {
    case 'less-than':
      return isTabletMax ? <>{children}</> : null;
    case 'greater-than':
      return isTabletMin ? <>{children}</> : null;
    default:
      return isTablet ? <>{children}</> : null;
  }
};

const Laptop: FC<MediaProps> = ({children, type}) => {
  const isLaptop = useMediaLaptop();
  const isLaptopMin = useMediaLaptop('greater-than');
  const isLaptopMax = useMediaLaptop('less-than');

  switch (type) {
    case 'less-than':
      return isLaptopMax ? <>{children}</> : null;
    case 'greater-than':
      return isLaptopMin ? <>{children}</> : null;
    default:
      return isLaptop ? <>{children}</> : null;
  }
};

const Desktop: FC<MediaProps> = ({children}) => {
  const isDesktop = useMediaDesktop();
  return isDesktop ? <>{children}</> : null;
};

Media.Mobile = Mobile;
Media.Phablet = Phablet;
Media.Tablet = Tablet;
Media.Laptop = Laptop;
Media.Desktop = Desktop;

export default Media;
