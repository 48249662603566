import {useMediaQuery} from 'react-responsive';
import {MediaType} from '@/types';
import {BREAKPOINTS} from '@/constants';

const useMediaLaptop = (type?: MediaType) => {
  const isLaptop = useMediaQuery({
    minWidth: BREAKPOINTS.md,
    maxWidth: BREAKPOINTS.lg - 1,
  });
  const isLaptopMin = useMediaQuery({
    minWidth: BREAKPOINTS.md,
  });
  const isLaptopMax = useMediaQuery({
    maxWidth: BREAKPOINTS.lg - 1,
  });

  switch (type) {
    case 'less-than':
      return !!isLaptopMax;
    case 'greater-than':
      return !!isLaptopMin;
    default:
      return !!isLaptop;
  }
};

export default useMediaLaptop;
