import {Route, Switch} from 'react-router';

import Home from '@/containers/Home';
import Profile from '@/containers/Profile';
import NotFound from '@/containers/NotFound';

const App = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/:userId" exact component={Profile} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default App;
