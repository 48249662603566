import {
  ButtonHTMLAttributes,
  FC,
  HTMLAttributes,
  MouseEvent,
  ReactNode,
  useCallback,
} from 'react';
import cn from 'classnames';

import Spinner from '@/components/Spinner';

import styles from './Button.module.scss';

interface ButtonProps extends HTMLAttributes<HTMLElement> {
  href?: string;
  size?: 'md' | 'lg';
  type?: 'button' | 'submit';
  theme?: 'primary' | 'dark';
  loading?: boolean;
  className?: string;
  children: ReactNode;
}

const Button: FC<ButtonProps> = ({
  size,
  href,
  theme,
  children,
  loading,
  className,
  onClick,
  ...props
}) => {
  const handleClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      loading && e.preventDefault();
      onClick?.(e);
    },
    [onClick, loading]
  );

  const buttonProps = {
    href,
    className: cn(
      styles.button,
      styles[`button--${size}`],
      styles[`button--${theme}`],
      {
        [styles['button--loading']]: loading,
      },
      className
    ),
    onClick: handleClick,
    ...props,
  };

  const buttonContent = (
    <div>{loading ? <Spinner className={styles.loader} /> : children}</div>
  );

  return href ? (
    <a target="_blank" rel="noreferrer" {...buttonProps}>
      {buttonContent}
    </a>
  ) : (
    <button {...buttonProps}>{buttonContent}</button>
  );
};

Button.defaultProps = {
  size: 'md',
  theme: 'primary',
  loading: false,
};

export default Button;
