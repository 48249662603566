interface Options {
  from?: number;
  to?: number;
}

const defaultOptions: Options = {
  from: 1,
  to: 0,
};

const obscureString = (str: string, options: Options = defaultOptions) => {
  if (!str) return str;
  options = { ...defaultOptions, ...options };
  const strLen = str.slice(options.from, str.length + options.to!).length;
  return (
    str.substring(0, options.from) +
    "*".repeat(strLen) +
    str.substring(str.length + options.to!)
  );
};

export default obscureString;
