import feature1 from '@/assets/images/home/image-1.svg';
import feature2 from '@/assets/images/home/image-2.svg';
import feature3 from '@/assets/images/home/image-3.svg';
import {FeatureWithId} from './Home.types';

export const FEATURES: FeatureWithId[] = [
  {
    id: 1,
    image: feature1,
    title: 'User sends you a link',
    description: 'or his QR-code <br>You open his personal page',
  },
  {
    id: 2,
    image: feature2,
    title: 'Enter contact details',
    description:
      'and you will receive a contact card <br>of the user in an SMS',
  },
  {
    id: 3,
    image: feature3,
    title: 'User also receives',
    description: 'your contact details',
  },
];
