import {useEffect, useRef, DependencyList, EffectCallback} from 'react';

const useEffectAfterMount = (
  callback: EffectCallback,
  deps?: DependencyList
) => {
  const componentJustMount = useRef(true);

  useEffect(() => {
    if (!componentJustMount.current) {
      return callback();
    }
    componentJustMount.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export default useEffectAfterMount;
