export const BREAKPOINTS = {
  lg: 1280,
  md: 1024,
  sm: 768,
  xs: 480,
  xxs: 384,
};

export const KEYBOARD_KEYS = {
  escape: 'Escape',
};

export const DEFAULT_PHONE_INPUT_PLACEHOLDER = '_';
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const APP_TITLE = 'Infinite Creator. Videos That Sell';
export const APP_STORE_URL =
  'https://apps.apple.com/us/app/infinite-creator/id1616340639';
export const GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=com.infinitecreator.app.prod';
export const DOWNLOAD_APP_URL = 'https://infinitecreator.app.link/app';
