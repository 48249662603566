import {useEffect} from 'react';
import {Link} from 'react-router-dom';

import notFoundImage from '@/assets/images/404.svg';

import styles from './NotFound.module.scss';
import {APP_TITLE} from '@/constants';

const NotFound = () => {
  useEffect(() => {
    document.title = `${APP_TITLE} | Page Not Found`;
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h1 className={styles.title}>
          Error: 404
          <br />
          Page Not Found
        </h1>
        <div className={styles.image}>
          <img src={notFoundImage} alt="" />
        </div>
        <div className={styles.message}>
          The page you were looking for doesn't exist.{' '}
          <Link to="/">Return to homepage</Link> or explore
        </div>
      </div>
    </div>
  );
};

export default NotFound;
