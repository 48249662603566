import {useMediaQuery} from 'react-responsive';

import {BREAKPOINTS} from '@/constants';

const useMediaDesktop = () => {
  const isDesktop = useMediaQuery({
    minWidth: BREAKPOINTS.lg,
  });

  return !!isDesktop;
};

export default useMediaDesktop;
