import { useState, useEffect } from "react";
import cn from "classnames";
import { useParams } from "react-router";

import axios from "@/api";

import Media from "@/components/Media";
import ProfileCard from "@/components/ProfileCard";
import PopupCredentials from "@/components/PopupCredentials";
import PopupDownload from "@/components/PopupDownload";
import WithError from "@/components/WithError";
import Loader from "@/components/Loader";

import { getNumberString, obscureString } from "@/helpers";

import { useScrollable, useEffectAfterMount } from "@/hooks";

import { RequestorInfo, UserEntity } from "@/types";
import { ProfileParams } from "@/types/params";

import styles from "./Profile.module.scss";
import { APP_TITLE } from "@/constants";

const Profile = () => {
  const [isPopupCredentialsOpen, setIsPopupCredentialsOpen] = useState(true);
  const [userData, setUserData] = useState<UserEntity | null>(null);
  const [error, setError] = useState<Error | unknown>(null);
  const [formError, setFormError] = useState<Error | unknown>(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isPopupDownloadOpen, setIsPopupDownloadOpen] = useState(false);
  const { isScrollable: isMainCardScrollable, ref: mainCardRef } =
    useScrollable<HTMLDivElement>();
  const { userId } = useParams<ProfileParams>();

  useEffect(() => {
    document.title = `${APP_TITLE} | Contact Card`;

    (async () => {
      try {
        const { data } = await axios.get<UserEntity>(`/users/${userId}`);

        document.title = `${APP_TITLE} | ${data.full_name}'s Contact Card`;

        const secretUserData: UserEntity = {
          ...data,
          company_name: obscureString(data.company_name),
          company_address: obscureString(data.company_address!),
          phone_number: obscureString(data.phone_number, { from: 2 }),
          work_email: obscureString(data.work_email!, { from: 1, to: -1 }),
        };

        setUserData(secretUserData);
      } catch (error) {
        console.error(error);
        setError(error);
      } finally {
        setIsDataLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectAfterMount(() => {
    if (!isPopupCredentialsOpen) {
      setIsPopupDownloadOpen(true);
    }
  }, [isPopupCredentialsOpen]);

  const handleFormSubmit = async ({
    name,
    countryCode,
    phone,
    email,
    events,
  }: any) => {
    const requestor: RequestorInfo = {
      email,
      full_name: name,
      phone_number: `+${countryCode}${getNumberString(phone)}`,
    };
    const requestee = userData?.preferred_username;

    setIsFormLoading(true);

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [contact, user] = await Promise.all([
        axios.post("/contact", {
          events,
          requestor,
          requestee,
        }),
        axios.get(`/users/${requestee}`),
      ]);

      setUserData(user.data);
      setIsPopupCredentialsOpen(false);
    } catch (error) {
      console.error(error);
      setFormError(error);
    } finally {
      setIsFormLoading(false);
    }
  };

  const handleCloseDownloadPopup = () => {
    setIsPopupDownloadOpen(false);
  };

  return (
    <Loader loading={isDataLoading && !error}>
      <WithError error={error}>
        <main className={styles.main}>
          <Media.Mobile>
            <ProfileCard userData={userData} />
          </Media.Mobile>
          <Media.Phablet type="greater-than">
            <div
              className={cn(styles.wrapper, {
                [styles.wrapperScrollable]: isMainCardScrollable,
              })}
            >
              <ProfileCard ref={mainCardRef} userData={userData} />
            </div>
          </Media.Phablet>
        </main>

        <PopupCredentials
          error={formError}
          userData={userData}
          isLoading={isFormLoading}
          isOpen={isPopupCredentialsOpen}
          onSubmit={handleFormSubmit}
        />
        <PopupDownload
          userData={userData}
          isOpen={isPopupDownloadOpen}
          onClose={handleCloseDownloadPopup}
        />
      </WithError>
    </Loader>
  );
};

export default Profile;
